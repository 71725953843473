@import "ap-interactive-assets/good/good_cond_regular.css";
@import "ap-interactive-assets/good/good_cond_bold.css";
@import "ap-interactive-assets/good/good_cond_regular_italic.css";

@font-face {
    font-family: "AP";
    src: url("ap-interactive-assets/ap/ap_var.woff2") format("woff2"), url("ap-interactive-assets/ap/ap_var.woff") format("woff");
    font-style: normal;
    font-display: swap;
    font-stretch: 25% 151%;
    font-weight: 100 900;
    
}

@font-face {
    font-family: "AP Italic";
    src: url("ap-interactive-assets/ap/ap_italic.woff2") format("woff2"), url("ap-interactive-assets/ap/ap_italic.woff") format("woff");
    font-style: italic;
    font-display: swap;
    font-stretch: 25% 151%;
    font-weight: 100 900;
}